import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact/contact')
    },
    {
        path: '/us',
        name: 'us',
        component: () => import('../views/us/us')
    },
    {
        path: '/search/:type?/:mode?',
        name: 'search',
        component: () => import('../views/search/search')
    },
    {
        path: '/property/:id',
        name: 'property',
        component: () => import('../views/property/property')
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: () => import('../views/thanks/thanks')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../views/privacy/privacy')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
